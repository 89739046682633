<template>
    <div class="case">
        <el-breadcrumb separator="/">
            <el-breadcrumb-item :to="{ path: '/' }">{{ $t('shou-ye') }}</el-breadcrumb-item>
            <el-breadcrumb-item
                :to="{
                    path: '/brandDetail',
                    query: {
                        id: vendorInfo.id
                    }
                }"
                replace
            >
                {{ name }}</el-breadcrumb-item
            >
            <el-breadcrumb-item>{{ $t('an-li-xiang-qing') }}</el-breadcrumb-item>
        </el-breadcrumb>

        <div class="center">
            <div class="title">
                {{ caseInfo.title }}
            </div>

            <div class="content" v-html="caseInfo.content"></div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'caseDetail',
    props: {
        vendorInfo: {
            type: Object,
            default: () => {
                return {};
            }
        }
    },
    data() {
        return {
            caseInfo: {}
        };
    },
    computed: {
        name() {
            return this.getName(this.vendorInfo, ['chCompanyName', 'enCompanyName']);
        }
    },
    created() {
        this.$http.get('/successCase/get/' + this.$route.query.id).then(res => {
            this.$emit('setStore', res.vendorInfoId);
            this.caseInfo = res;
        });
    },
    methods: {}
};
</script>

<style lang="scss" scoped>
@import '../../styles/variables';
.case {
    @include center-content(470);
    box-sizing: border-box;
    padding-bottom: 50px;

    .el-breadcrumb {
        padding: 30px 0 20px;
    }
}

.center {
    background: #ffffff;
    padding: 40px 50px;

    .title {
        font-size: 24px;
        font-weight: bold;
        color: #000000;
        line-height: 33px;
        padding-bottom: 30px;
        border-bottom: 1px solid #f2f3f5;
        text-align: center;
    }
}
</style>
